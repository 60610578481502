import React, { Component } from "react";
import AUX from "../hoc/Aux_";
import { Link } from "react-router-dom";
import { history } from "../_helpers/history";
import { LOGO_FULL_URL, BASE_URL, EMAIL_REGEX } from "../_helpers/constants";
import Axios from "axios";
import Promises from "../_helpers/utils";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import PendingVerification from "../_components/PendingVerification";
import AuthSidebar from "../_components/AuthSidebar";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
// import { refreshTokenSetup } from "../utils/refreshToken";
export default class Pages_login_2 extends Component {
  constructor(props) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this);
    this.state = {
      username: "",
      password: "",
      isHiddenPass: true,
      submitted: false,
      keepLogin: false,
      errorText: "",
      successText: "",
      socialLogin: 0,
      isClicked: false,
      stateDomain: window.location.host.split(".")[1]
        ? window.location.host.split(".")[0]
        : "localhost",
      verificationPendig: false,
    };
  }
  getQueryParams = (query = null) =>
    (query || window.location.search.replace("?", ""))
      .split("&")
      .map((e) => e.split("=").map(decodeURIComponent))
      .reduce((r, [k, v]) => ((r[k] = v), r), {});
  componentDidMount = () => {
    const token = Promises.getToken();
    if (Promises.getToken()) {
      history.push("/dashboard");
    }
  };

  receiveMessage = (event) => {
    const message = event.data.message;
    switch (message) {
      case "getAppData":
        console.log("event.data", event.data);
        this.onSuccess(event.data.value);
        break;
    }
  };

  onSuccess(res) {
    if (res && res.profileObj && res.profileObj.email) {
      this.setState({ username: res.profileObj.email, socialLogin: 1 });
      this.onLogin();
    } else {
      NotificationManager.success("Something went wrong!!");
    }
  }
  getHostDomain = () => {
    const parts = window.location.host.split(".");

    if (parts.length === 1) {
      return "localhost";
    } else if (parts[0] !== "www") {
      return parts[0];
    } else {
      return parts[1];
    }
  };

  onLogin = () => {
    this.setState({ submitted: true });
    const { username, password, socialLogin } = this.state;
    if (username.length == 0) {
      this.setState({ errorText: "Please Enter Username" });
    } else if (password.length == 0 && socialLogin == 0) {
      this.setState({ errorText: "Please Enter Password" });
    } else {
      const data = {
        email: username,
        password: password,
        socialLogin: socialLogin,
        domain: this.getHostDomain(),
      };

      console.log(data);

      Axios.post(`${BASE_URL}auth/sign-in`, data)
        .then((res) => {
          console.log("login res", res);
          if (res.data?.verificationPendig) {
            NotificationManager.warning(
              "Please verify your email address to login."
            );
            this.setState({ verificationPendig: true });
          } else if (res.status == 200) {
            if (data.domain == "oyetest") {
              NotificationManager.success(
                "We are redirecting to your workspace please login your workspace."
              );
              setTimeout(() => {
                window.location = `https://${res.data.domain}.oyetest.com/login`;
              }, 3000);
            } else if (data.domain == "joslery") {
              NotificationManager.success(
                "We are redirecting to your workspace please login your workspace."
              );
              setTimeout(() => {
                window.location = `https://${res.data.domain}.joslery.com/login`;
              }, 3000);
            } else {
              // this.setState({ successText: 'Successfully login'})
              console.log("Login Respinse -->", res.data);
              Promises.setUserId(res.data.id);
              Promises.setToken(res.data.token);
              const fullName = `${res.data.firstname} ${res.data.lastname}`;
              Promises.setName(fullName);
              Promises.setFirstName(res.data.firstname);
              Promises.setLastName(res.data.lastname);
              Promises.setEmail(res.data.email);
              Promises.setOrg(res.data.organization);
              Promises.setLocalIteam("domain", data.domain);
              Promises.setOrgName(res.data.organizationName);
              Promises.setOrgLogo(res.data.organizationLogo);
              Promises.setProfileImage(res.data.userProfilePicture);
              // Promises.setPage('dashboard')
              setTimeout(() => {
                const searchParams = new URLSearchParams(
                  this.props.location.search
                );
                if (
                  searchParams.get("redirect") &&
                  searchParams.get("redirect") !== "null"
                ) {
                  console.log("TRYING TO GO BACK");
                  history.goBack();
                } else history.push("/dashboard");
                console.log("why am i still here");
              }, 1000);
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
          this.setState({
            errorText: "Invalid username or password or domain",
          });
        });
    }
  };

  validateEmail = (email) => {
    return EMAIL_REGEX.test(String(email).toLowerCase());
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { username, password, errorText, successText } = this.state;

    return (
      <AUX>
        <div className="custom-container pl-[78px] w-[100%]">
          <AuthSidebar />
          {!this.state.verificationPendig && (
            <div
              className="custom-container-2 flex !flex-col !items-center !justify-center"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="form-custom-container">
                <h2
                  className="font-raleway"
                  style={{ fontSize: 33, fontWeight: 700 }}
                >
                  {" "}
                  Welcome Back!{" "}
                </h2>
                <p
                  className="heading-description subheading"
                  // style={{ fontWeight: 400, fontSize: 14 }}
                >
                  Don't have an account ?{" "}
                  <Link to="/register"> Signup Now </Link>{" "}
                </p>
                <form>
                  <div className="form-field">
                    <label
                      className="form-field-label text-primary-description font-roboto !font-[500]"
                      style={{ fontSize: 14 }}
                      htmlFor="username"
                    >
                      Email ID
                    </label>
                    <input
                      className="form-field-input px-3"
                      type="text"
                      id="username"
                      value={username}
                      onChange={(e) => {
                        this.setState({ username: e.target.value });
                        this.setState({ errorText: "" });
                      }}
                    />
                  </div>
                  <div className="form-field no-margin">
                    <div className="flex flex-row justify-between items-center">
                      <label
                        className="form-field-label input-label"
                        // style={{ fontSize: 14}}
                        htmlFor="userpassword"
                      >
                        Password
                      </label>
                      <div
                        className="flex cursor-pointer gap-x-1 items-center"
                        onClick={() => {
                          this.setState({
                            isHiddenPass: !this.state.isHiddenPass,
                          });
                        }}
                      >
                        {!this.state.isHiddenPass ? (
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.5687 4.06778L15.9554 3.45449C15.7821 3.28117 15.4621 3.30784 15.2621 3.5478L13.1286 5.66777C12.1686 5.25448 11.1153 5.05448 10.0086 5.05448C6.71519 5.06776 3.86204 6.98774 2.48861 9.74791C2.40858 9.92124 2.40858 10.1345 2.48861 10.2812C3.12855 11.5879 4.08861 12.6679 5.28861 13.4812L3.54195 15.2545C3.34195 15.4545 3.31528 15.7745 3.44865 15.9478L4.06193 16.5611C4.23526 16.7344 4.55525 16.7077 4.75525 16.4678L16.4618 4.76121C16.7152 4.56132 16.7419 4.24135 16.5685 4.06801L16.5687 4.06778ZM10.7153 8.09435C10.4886 8.041 10.2486 7.97437 10.022 7.97437C8.88859 7.97437 7.98202 8.88106 7.98202 10.0143C7.98202 10.241 8.03536 10.4809 8.10199 10.7076L7.20856 11.5877C6.94193 11.121 6.79528 10.6009 6.79528 10.0143C6.79528 8.24103 8.22196 6.81435 9.99528 6.81435C10.582 6.81435 11.102 6.961 11.5686 7.22763L10.7153 8.09435Z"
                              fill="#666666"
                              fill-opacity="0.8"
                            />
                            <path
                              d="M17.5287 9.7479C17.0621 8.81453 16.4487 7.97458 15.6888 7.29456L13.2087 9.7479V10.0145C13.2087 11.7878 11.782 13.2145 10.0087 13.2145H9.74208L8.16876 14.7878C8.75548 14.9078 9.36877 14.9878 9.96877 14.9878C13.2622 14.9878 16.1154 13.0679 17.4888 10.2944C17.6088 10.1077 17.6088 9.9211 17.5287 9.74776L17.5287 9.7479Z"
                              fill="#666666"
                              fill-opacity="0.8"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="14"
                            height="9"
                            viewBox="0 0 14 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.868077 5.80351C1.46578 2.92461 3.96439 0.879054 6.775 0.879054C9.58473 0.879054 12.0833 2.92461 12.6819 5.80351C12.7056 5.91775 12.7736 6.01786 12.8709 6.08183C12.9682 6.14579 13.0869 6.16837 13.2008 6.14459C13.3146 6.12081 13.4145 6.05262 13.4782 5.95502C13.542 5.85743 13.5645 5.73842 13.5408 5.62419C12.8598 2.34971 10.0115 0 6.775 0C3.53846 0 0.690168 2.34971 0.00920718 5.62419C-0.0145012 5.73842 0.00800534 5.85743 0.0717757 5.95502C0.135546 6.05262 0.235356 6.12081 0.34925 6.14459C0.463143 6.16837 0.58179 6.14579 0.679089 6.08183C0.776388 6.01786 0.844369 5.91775 0.868077 5.80351ZM6.76624 2.63716C7.57976 2.63716 8.35996 2.96131 8.93521 3.5383C9.51046 4.11529 9.83363 4.89786 9.83363 5.71385C9.83363 6.52984 9.51046 7.31241 8.93521 7.8894C8.35996 8.46639 7.57976 8.79054 6.76624 8.79054C5.95271 8.79054 5.17251 8.46639 4.59726 7.8894C4.02201 7.31241 3.69884 6.52984 3.69884 5.71385C3.69884 4.89786 4.02201 4.11529 4.59726 3.5383C5.17251 2.96131 5.95271 2.63716 6.76624 2.63716Z"
                              fill="#6A707C"
                            />
                          </svg>
                        )}
                        <p
                          className="font-roboto opacity-70"
                          style={{
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "19.5px",
                            textAlign: "right",
                          }}
                        >
                          {this.state.isHiddenPass ? "Show" : "Hide"}
                        </p>
                      </div>
                    </div>
                    <input
                      type={this.state.isHiddenPass ? "password" : "text"}
                      id="userpassword"
                      className="py-3"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.onLogin();
                        }
                      }}
                      value={password}
                      onChange={(e) => {
                        this.setState({ password: e.target.value });
                        this.setState({ errorText: "" });
                      }}
                    />
                  </div>
                  {errorText.length > 0 && (
                    <div className="alert alert-danger mb-0" role="alert">
                      {errorText}
                    </div>
                  )}

                  {successText.length > 0 && (
                    <div className="alert alert-success" role="alert">
                      {successText}
                    </div>
                  )}
                  <Link
                    to="/forgetpassword"
                    className="hyper-link font-poppins text-[#316BFF] my-2"
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                      letterSpacing: 0.5,
                    }}
                  >
                    Forgot your password?
                  </Link>
                  <div className="login-btn-div d-flex flex-column">
                    <button
                      type="button"
                      className="btn primary_btn"
                      onClick={this.onLogin}
                      // style={{
                      //   maxWidth: 160,
                      //   marginTop: 16,
                      //   marginBottom: 16,
                      //   borderRadius: 8,
                      // }}
                      disabled={!this.state.username || !this.state.password}
                    >
                      Login
                    </button>
                    {/* or */}
                    {false &&
                      this.state.stateDomain &&
                      (this.state.stateDomain !== "www" ||
                        this.state.stateDomain !== "oyetest") && (
                        <iframe
                          src="https://oyetest.com/oyetest-social-login/?rs:embed=true"
                          style={{ border: "none", marginTop: 16 }}
                          title="description"
                          className="google-btn"
                        ></iframe>
                      )}
                  </div>
                </form>
              </div>
            </div>
          )}
          {this.state.verificationPendig && (
            <div className="flex items-center justify-center flex-grow">
              <PendingVerification
                defaultResendCall={true}
                email={this.state.username}
              />
            </div>
          )}
          <NotificationContainer />
        </div>
      </AUX>
    );
  }
}
